<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        
    },
    data() {
        return {

            fields: [
                { key: "ville", label: "Ville" },
                { key: "rayon", sortable: true, label: "Rayon (Km)" },
                { key: "active", sortable: true, label: "Statut" },
               
                { key: "action", sortable: true, label: "Action", },


            ],
            usersFields: [
                { key: "name", label: "Nom" },
                { key: "phone", sortable: true, label: "Téléphone" },
                { key: "country", sortable: true, label: "Pays" },
                // { key: "montantAssurance", sortable: true, label: "Assurance" },
                // { key: "montantAttenteParMin", sortable: true, label: "Tarif attente", },
                { key: "action", sortable: true, label: "Action", },


            ],
            cityData: [],
            showModal: false,
            city: {
                nom: "",
                longitude: null,
                latitude: null,
            },
            rayon: 0,
            areaUsers: [],
            filteredUsers: [],
            newRayonId: null,

            title: "Zone  d'activité",
            items: [
                {
                    text: "Dashboard",
                },
                {
                    text: "Zone d'activité",
                    active: true,
                },
            ],

            totalRows: 1,
            changeCurrent: 1,
            changePerPage: 100,
            currentPage: 1,
            currentPageD: 1,
            perPage: 100,
            perPageD: 100,
            pageOptions: [100, 250, 500],
            pageOptionsD: [100, 250, 500],
            filter: null,
            filterOn: [],
            sortBy: "orderid",
            sortDesc: false,
            newRayon: null,
            coordinate: [],
            startLocation: {
                lat: 6.366667,
                lng: 2.433333,
            },
            assoIcon: { url: require('@/assets/images/placeholders/asso.gif'), scaledSize: { width: 60, height: 60 } },


        };


    },
    computed: {
        /**
         * Total no. of records
         */
        // rows() {
        //     return this.dataUser.length;
        // },
        // rowsinactif() {
        //     return this.dataUserD.length;
        // },
    },
    async mounted() {

        // await   this.dataMap()



    },
    created() {
        this.dataMap()
    },
    methods: {

        search() {
            if (this.filter) {
                this.filteredUsers = this.areaUsers.filter(el => el.name.toLowerCase().includes(this.filter.toLowerCase()) || el.phone.includes(this.filter))
                //this.filteredUsers
            } else {

                this.filteredUsers = []

            }
        },
        // GET /zones-courses

        async getUser() {
            const data = await apiRequest('GET', 'dash/user/actif', undefined, false)
            if (data && data.data) {
                console.log('getUser.data:', data.data)

                const formattedTable = data.data.map((user) => {

                    return {
                        id: user.id,
                        firstname: user.firstname + ' ' + user.lastname,
                        email: user.email,
                        phone: user.phoneCode + ' ' + user.phone,
                        sexe: user.sexe,
                        optionsH: {
                            position: {
                                lat: user.latVille,
                                lng: user.latVille,
                            },

                            title: user.ville,
                            icon: this.mapHomeMarker,
                        },
                        latVille: user.latVille,
                        lngVille: user.lngVille,
                        ville: user.ville,
                        emailVerified: user.emailVerified,
                        profilPicture: user.profilPicture ? user.profilPicture : null,
                        status: user.active,
                        createdAt: new Date(user.createdAt).toLocaleString("FR-fr"),
                    }
                })

                this.users = formattedTable

                console.log("this user", this.users)

                console.log("over")
            }
        },
        async dataMap() {
            const data = await apiRequest(
                "GET",
                "zones-courses"
            );
            if (data && data.status == 200) {
                console.log('activity', data.data)

                const map = data.data.map(element => {
                    return {

                        lat: element.departLat,
                        lng: element.departLng,
                    };

                })

                this.coordinate = map;
                console.log('this.mapedData', this.coordinate)
            }
        },











    },


};

/* const dots = document.querySelector('.loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row rowBase">
            <div class="col-lg-12 ">
                <div class="card p-4 ">

                    <div class="mb-4">
                        Zone d'activité
                    </div>
                    <div class="card-body">
                        <!-- <GmapMap :center="startLocation" :zoom="10" map-type-id="terrain" style="width: 100%; height: 500px">
                            <GmapMarker v-for="(item, index) in coordinate" :key="index" :position="item" :clickable="true" :draggable="false"
                                @click="center = markers.position">

                                <GmapInfoWindow :label="markers.label">
                                    <div v-if="isDriver == true" style="font-size: 1.2rem;">
                                        Conducteur
                                    </div>
                                    <div v-if="isDriver == false" style="font-size: 1.2rem;">
                                        Passager
                                    </div>
                                </GmapInfoWindow>

                            </GmapMarker>
                        </GmapMap> -->
                        <GmapMap :center="startLocation" :zoom="14" map-type-id="terrain"
                            style="width: 100%; height: 800px">
                            <gmap-info-window :options="infoOptions" :position="infoPosition" :opened="infoOpened"
                                @closeclick="infoOpened = false">
                                {{ infoContent }}
                            </gmap-info-window>
                            <GmapMarker style="opacity: 0.5;" v-for="(item, index) in coordinate" :key="index" :icon="assoIcon" 
                                :position="{ lat: item.lat, lng: item.lng }" :clickable="true" :draggable="false" 
                                 />
                        </GmapMap>
                    </div>
                </div>
            </div>
        </div>

    </Layout>

</template>


<style>
.page-item.active .page-link {
    background-color: #3AAA35 !important;

}

.box {
    background-color: #3AAA35;
    color: white;
    border-radius: 5px;
    font-size: 1.5rem;
    padding: 20px;
    box-shadow: 5px 10px rgba(144, 238, 144, 0.417);
}

.box-tarif {
    font-weight: light;
    font-size: 1em;
}

.formule {
    font-weight: bold;
}

.detail-prix {
    font-size: 0.6em;
    font-weight: light;
}

thead {
    background: #3AAA35;
}


thead div {
    color: #fff;

}

.slide-enter-active,
.slide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.lineField td:nth-child(5) {
    max-width: 250px !important;
    white-space: nowrap;
    overflow-x: scroll !important;
    overflow-y: auto !important;
}

.lineField td:nth-child(2) {
    max-width: 200px !important;
    white-space: nowrap;
    overflow: auto;
}


/* td{
  max-height: 100px !important;
  overflow-y:scroll !important ;
} */


/* td {
  max-width: 200px !important;
  white-space: nowrap; 
  overflow: auto;
} */
.lien {
    color: #3AAA35 !important;
}

.bg-color-custom {
    background-color: #3AAA35 !important;
    color: white;

}

.seat-bg {
    background-color: #5bcd3e;
}

.day {
    background-color: rgb(231, 225, 225);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    /* Ajustez la taille selon vos besoins */
    height: 40px;
    border-radius: 50%;
    font-size: 14px;
}

.day:hover {

    color: #fff;
    background-color: rgb(104, 87, 131) !important;
}

.input {
    right: 13px;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 35px;
    bottom: 0;
    flex: 60%;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
    background: #ffffff;
}

.containerDot {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9edbe;
    border: 1px solid #f0c36d;
    font-size: 2rem;
    color: hsl(0, 0%, 12%);
    width: 250px;
    height: 40px;
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.pac-container {
    z-index: 10000 !important;
}

.input input {
    padding: 3px 3px 3px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    color: #495057;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
}

.input input:focus {
    border: 3px solid #3aaa35;
}

.inputCol {
    position: relative;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}

.nav-tabs .nav-link {
    background: #dc143c;
    transition: all .5s;
}

.nav-tabs .nav-link a {
    color: #fff;
}

.text-red {
    color: #dc143c;
}

.nav-tabs .nav-link a {
    color: #fff !important;
}

.text-green {
    color: #3AAA35;
}

.nav-tabs .nav-link a {
    color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
    color: blue;
    font-weight: 600 !important;
    vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
    font-weight: 600;
    background: #3AAA35;
    border-bottom: 1px solid blue;
}

.nav-tabs-custom .nav-item .nav-link::after {
    border-bottom: 1px solid #000000;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    margin: auto;
    background-color: #d5d5d5;

    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
        10px 10px 15px rgba(70, 70, 70, 0.12);
}

input[type="checkbox"]:checked {
    background-color: #5bcd3e;
}

input[type="checkbox"]:checked:after {
    display: block;

}

input[type="checkbox"]:after {
    font-weight: 600;
    font-family: FontAwesome;
    content: "\f00c";
    width: inherit;
    height: inherit;

    font-size: 20px;
    color: white;
    display: none;
}


.seat-container {
    border: 2px solid black;
    width: 35px;
    height: 35px;
    justify-content: center;
    border-radius: 5px;
    margin-right: 5px;
    /* Add margin between seats for spacing */
}

.row.d-flex .d-inline {
    display: flex;
    flex-direction: row;
}
</style>
